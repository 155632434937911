import React from 'react';
import {Card, Cell, List, Space} from 'react-vant';
import {getDailyUsageRange} from "@/api/query";
import {useRequest} from "ahooks";
import {useSearchParams} from 'react-router-dom';
import UsageChart from "@/pages/History/components/UsageChart";
import moment from "moment";
import {dateFormat, volumeFormat} from "@/utils/util";

const Query: React.FC = () => {
    const [searchParams] = useSearchParams();

    const {data} = useRequest(() => getDailyUsageRange({iccid: searchParams.get('iccid'),
        start: moment().subtract(31, 'day').format('YYYY-MM-DD'),
        end: moment().subtract(1, 'day').format('YYYY-MM-DD')
    }), {
        onSuccess() {
        },
        manual: false,
    });
    return (
        <Space block={true} direction={'vertical'}>
            <Card>
                <Card.Body>
                    <UsageChart data={data}/>
                </Card.Body>
            </Card>
            <Card>
                <Card.Header>
                    Usage Details Per Day
                </Card.Header>
                <Card.Body>
                    <List finished={true} onLoad={null} >
                        {data?.map((item, index) => (
                            <Cell key={index}  title={dateFormat(item.day, 'YYYY-MM-DD')} value={volumeFormat(item.usage)}>
                            </Cell>
                        ))}
                    </List>
                </Card.Body>
            </Card>
        </Space>
    );
}

export default Query;
