// src/api/axiosInstance.js
import axios from 'axios';
import {Notify } from 'react-vant';

const axiosInstance = axios.create({
    baseURL: '',
    headers: {
        'Content-Type': 'application/json',
    },
});

// Request handler
axiosInstance.interceptors.request.use(
    (config) => {
        // Add any custom logic before request is sent
        return config;
    },
    (error) => {
        // Handle the request error
        return Promise.reject(error);
    }
);

// Response handler
axiosInstance.interceptors.response.use(
    (response) => {
        // Any status code within the range of 2xx triggers this function
        if (!response.data.success) {
            Notify.show({
                type: 'danger',
                message: response.data.detail
            });
            return Promise.reject(response.data.detail);
        }
        return response.data.data;
    },
    (error) => {
        // Any status codes outside the range of 2xx trigger this function
        if (error.response) {
            // The request was made, and the server responded with a status code
            // that falls out of the range of 2xx
            Notify.show({
                type: 'danger',
                message: error.response.data.message || 'An error occurred'
            });
        } else if (error.request) {
            // The request was made but no response was received
            Notify.show({
                type: 'danger',
                message: 'Network error. Please check your connection.'
            });
        } else {
            // Something happened in setting up the request that triggered an Error
            Notify.show({
                type: 'danger',
                message: error.message
            });
        }
        // Any status codes outside the range of 2xx trigger this function
        return Promise.reject(error);
    }
);

export default axiosInstance;
