import React, {useEffect} from 'react';
import './index.scss';
import {NavBar, NavBarProps} from 'react-vant'

interface MainLayoutProps {
    children: React.ReactNode;
    title: string;
    navBarConfig: NavBarProps,
}

const MainLayout: React.FC<MainLayoutProps> = ({children, title, navBarConfig}) => {
    useEffect(() => {
        document.title = title;
    }, [title]);
    return (
        <div className={`main-layout`}>
            <NavBar {...navBarConfig} title={title}/>
            <div className="content layout-content">
                {children}
            </div>
        </div>
    );
};

export default MainLayout;
