import axiosInstance from "@/api/axiosInstance";

export async function checkExist(data: any) {
    return axiosInstance<number>('/3.0/h5/check/exist', {
        method: 'POST',
        params: data,
    });
}
export async function getActivationRatePlan(data: any): Promise<any> {
    return axiosInstance<any>('/3.0/h5/getActivationRatePlan', {
        method: 'POST',
        params: data,
    });
}
export async function getHistoryRatePlan(data: any): Promise<any> {
    return axiosInstance<any>('/3.0/h5/getHistoryRatePlan', {
        method: 'POST',
        params: data,
    });
}

/**
 * 日用量范围查询
 */
export async function getDailyUsageRange(data: any): Promise<any> {
    return axiosInstance<any>('/3.0/h5/getDailyUsageRange', {
        method: 'POST',
        params: data,
    });
}

/**
 * 查询计划套餐
 */
export async function getRatePlan(data: any): Promise<any> {
    return axiosInstance<any>('/3.0/h5/getRatePlan', {
        method: 'POST',
        params: data,
    });
}
/**
 * 单卡可续费套餐信息
 */
export async function renewableRatePlan(data: any): Promise<any> {
    return axiosInstance<any>('/3.0/h5/renewable/ratePlan', {
        method: 'POST',
        params: data,
    });
}
/**
 * 续费记录
 */
export async function ratePlanRecords(data: any): Promise<any> {
    return axiosInstance<any>('/3.0/h5/ratePlan/records', {
        method: 'POST',
        params: data,
    });
}
/**
 * 订单金额计算
 */
export async function payCal(data: any): Promise<any> {
    return axiosInstance<any>('/3.0/h5/pay/cal', {
        method: 'POST',
        params: data,
    });
}
/**
 * 创建订单
 */
export async function payCreate(data: any): Promise<any> {
    return axiosInstance<any>('/3.0/h5/pay/create', {
        method: 'GET',
        params: data,
    });
}
