import React from "react";

export default () => {
    return <>
        <p>
            You Can Also Contact Us Here:
        </p>
        <p>
            Company: Hangzhou Simboss Network Technology Co., Ltd.
        </p>
        <p>
            Address: Room 4503-1, Building 2, Europe and America Financial City,
            Cangqian Street, Yuhang District, Hangzhou City, Zhejiang Province, China.
        </p>
        <p>
            If you are planning on visiting our offices for any reason, please call or
            email Customer Service ahead of time to schedule a visit.
        </p>
        <p>
            Phone: 400-668-5196
        </p>
        <p>
            Email: simbosskefu@qipeng.com
        </p>
    </>
}
