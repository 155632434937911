import React from "react";

export default () => {
    return <>
        <h1 style={{textAlign: "center"}}>Privacy policy</h1>
        <p>
            Hangzhou Simboss Network Technology Co., Ltd. doing business as Simboss
            (https://client-intl.simboss.com), recognizes the importance of your
            privacy. This privacy policy tells you what personal information we collect
            and how we use it. Simboss may change this privacy policy from time to time,
            in its sole discretion, as discussed in section '8. Changes to This Policy'
            below. By registering an account or otherwise using or visiting any Simboss
            website, application, product, software, tool, data feed, and/or service
            (collectively the "Service"), you understand and agree to the terms of this
            policy. Please read this policy carefully.
        </p>
        <p>
            If you have an unresolved privacy or data use concern that we have not
            addressed satisfactorily, please contact us directly at
            simbosskefu@qipeng.com or find more information under section '6. How Does A
            User Change Or Update Information'.
        </p>
        <p>
            Simboss is committed to your privacy. This privacy notice explains how we
            collect, use, disclose, retain, and protect your personal information.
        </p>
        <p>
            A few key principles regarding GDPR;A clear purpose: the controller must
            specifically inform the customer of the use he will make of his personal
            data by collecting them.Relevant data: the controller must only collect data
            that is strictly necessary for his treatment; this is the principle of
            minimizing collection.The retention period: the personal data must be kept
            enough time to the controller to achieve its purpose; beyond that time, the
            data must be deleted.The rights of individuals: individuals can exercise
            their rights over the personal data held by the controller: access,
            rectification or deletion.
        </p>
        <p>
            1. What Information Do We Collect?
        </p>
        <p>
            1.1 Personally-Identifiable Information
        </p>
        <p>
            We may collect personally identifiable information when you specifically and
            knowingly provide it to us, for example when you sign up for our newsletter
            or chat, create an account, request more information on our contact us page,
            respond to a survey or questionnaire and provide personal information such
            as your email address, name, phone number or other information. Where
            applicable, personally identifiable information includes personal data as
            defined in applicable law. We may collect personally identifiable
            information when you specifically and knowingly provide it to us, for
            example when you sign up for our newsletter or chat, create an account,
            request more information on our contact us page, respond to a survey or
            questionnaire and provide personal information such as your email address,
            name, phone number or other information. Where applicable, personally
            identifiable information includes personal data as defined in applicable
            law.
        </p>
        <p>
            This Privacy Policy does not apply to the privacy practices of third parties
            that we do not own or control, including but not limited to any third-party
            websites, services, applications, online resources to which this Site may
            link or otherwise reference (collectively Third Party Services or TPS) that
            you may access through the Services. We take no responsibility for the
            content or privacy practices of any TPS. We encourage you to carefully
            review the privacy policies of any TPS you access.
        </p>
        <p>
            Simboss does not consider personally identifiable information to include
            information that has been anonymized so that it does not allow a third party
            to identify a specific individual. We collect and use your
            personally-identifiable information to provide the services, operate and
            improve our service, provide customer service, perform research and analysis
            aimed at improving our products, service and technology, and display content
            that is customized to your interests and preferences.
        </p>
        <p>
            You may always choose not to provide personally identifiable information,
            but if you choose so, certain parts of the Service may not be available to
            you. If you have registered an account with us, you will have agreed to
            provide your personally identifiable information in order to access the
            services. This consent provides us with the legal basis we require under
            applicable law to process your data. You maintain the right to withdraw such
            consent at any time. If you do not agree to our use of your personal data in
            line with this policy, please do not use our Services.
        </p>
        <p>
            1.2 Sensitive Personal Data
        </p>
        <p>
            Subject to the following paragraph, we ask that you do not send us, and you
            do not disclose, any sensitive personal data (e.g., social security numbers,
            information related to racial or ethnic origin, political opinions, religion
            or other beliefs, health, biometrics or genetic characteristics, criminal
            background or trade union membership) on or through the services or
            otherwise to us.
        </p>
        <p>
            If you send or disclose any sensitive personal data to us when you submit
            content to the services, you consent to our processing and use of such
            sensitive personal data in accordance with this policy. If you do not
            consent to our processing and use of such sensitive personal data, you must
            not submit such content to us. Please note that even if these information
            are provided, we will not store them anywhere on our side.
        </p>
        <p>
            1.3 Non-Personally-Identifiable Information
        </p>
        <p>
            We may collect and aggregate non-personally identifiable information which
            is information which does not permit you to be identified or identifiable
            either by itself or in combination with other information available to a
            third party. This information may include information such as a website that
            referred you to us, your IP address, browser type and language, hardware
            types, geographic location, and access times and durations. We also may
            collect navigational information, including information about the service
            content or pages you view, the links you click, and other actions taken in
            connection with the service. We use this information to analyze usage
            patterns as part of making improvements to the Service.
        </p>
        <p>
            2. What Do We Do With The Information That We Collect?
        </p>
        <p>
            2.1 Information that we collect
        </p>
        <p>
            Except as disclosed in this privacy policy, Simboss does not share your
            personal information with any outside parties.
        </p>
        <p>
            Simboss will use the personally identifiable information directly provided
            by you solely for the purpose for which you have provided it, which may
            include:
        </p>
        <p>
            to operate, maintain, and improve the Services;
        </p>
        <p>
            to manage your account, including to communicate with you regarding your
            account;
        </p>
        <p>
            to operate and administer any promotions you participate in on any site or
            application;
        </p>
        <p>
            to respond to your comments and questions and to provide customer service;
        </p>
        <p>
            to send information including technical notices, updates, security alerts,
            and support and administrative messages; with your consent, to send you
            marketing emails about upcoming promotions, and other news, including
            information about products and services offered by us and our affiliates. We
            retain information about your marketing preferences for a reasonable period
            of time from the date you last expressed interest in our content, products
            or services, such as when you last opened an email from us. We retain
            information derived from cookies and other tracking technologies for a
            reasonable period of time from the date such information was created. You
            may opt-out of receiving such information at any time: such marketing emails
            tell you how to opt-out. Please note, even if you opt-out of receiving
            marketing emails, we may still send you non-marketing emails. Non-marketing
            emails include emails about your account with us (if you have one) and our
            business dealings with you;
        </p>
        <p>
            to process payments you make via the Services; and as we believe necessary
            or appropriate (a) to comply with applicable laws; (b) to comply with lawful
            requests and legal process, including to respond to requests from public and
            government authorities; (c) to enforce our Policy; and (d) to protect our
            rights, privacy, safety or property, and/or that of you or others.
        </p>
        <p>
            We may share this information with service providers who perform services on
            our behalf, such as those services listed above, or other services like
            processing information requests, displaying stored data you access, to
            assist us in marketing, to conduct audits, etc. Those companies will be
            permitted to obtain only the personal information they need to provide the
            service they provide, will be required to maintain the confidentiality of
            the information, and will be prohibited from using it for any other purpose.
        </p>
        <p>
            We may also use information you provide to better serve you, and, if you
            have given your consent for us to do so, to send you email or text messages
            concerning offers from our partners and other third parties that we think
            may be of interest to you. If you do not wish to receive marketing emails,
            you may adjust your Personal Information Preferences as described below or
            follow the unsubscribe or stop instructions included within each
            communication.
        </p>
        <p>
            We will only retain your personally identifiable information as long as
            reasonably required to provide you with the Services unless a longer
            retention period is required or permitted by law (for example, for
            regulatory purposes). Simboss will provide you with information about
            Simboss data and how it will be used upon request. Should your data stored
            by Simboss be incorrect, Simboss will correct it. In this regard, you can
            write to Simboss at the address listed in section ‘6. How Does A User Change
            Or Update Information’ or contact them in any other way. In addition,
            customers can view the data in their account that Simboss has stored about
            them and correct it if necessary.
        </p>
        <p>
            You may contact us anytime to opt-out of: (i) direct marketing
            communications; (ii) our collection of sensitive personal data; (iii) any
            new processing of your personal data that we may carry out beyond the
            original purpose. Please note that your use of some of the Services may be
            ineffective upon opt-out. You may also: (A) access the data we hold about
            you at any time via your account or by contacting us directly; (B) update or
            correct any inaccuracies in your personal data by contacting us; (C) in
            certain situations, for example when the data we hold about you is no longer
            relevant or is incorrect, you can request that we erase your data. You may
            contact us at simbosskefu@qipeng.com.
        </p>
        <p>
            anytime for any other questions you may have about your personally
            identifiable information and our use of it.
        </p>
        <p>
            3. Disclosure
        </p>
        <p>
            As a general rule, Simboss will not disclose any of your personally
            identifiable information except under one of the following circumstances:
        </p>
        <p>
            we have your permission, including the permission granted by your acceptance
            of this Privacy Policy;
        </p>
        <p>
            we determine in good faith that it is legally required to be revealed by any
            relevant statute, regulation, ordinance, rule, administrative or court
            order, decree, or subpoena;
        </p>
        <p>
            it is information that we determine must be disclosed to correct what we
            believe to be false or misleading information or to address activities that
            we believe to be manipulative, deceptive or otherwise a violation of law;
            where you are otherwise notified at the time we collect the data; where we
            need to share your information to provide the product or service you have
            requested;
        </p>
        <p>
            when such disclosure is made subject to confidentiality restrictions in
            connection with a sale, merger, transfer, exchange, or other disposition
            (whether of assets, stock, or otherwise) of all or a portion of the business
            conducted by Simboss. Simboss may share the non-personally identifiable
            information that Simboss gathers, in aggregate form only, with advertisers
            and other partners.
        </p>
        <p>
            4. Children's Policy
        </p>
        <p>
            The Terms of service clearly provide that Users must be (i) 18 or older, or
            (ii) 13 and older if either (a) an emancipated minor, or (b) he/she possess
            legal parental or guardian consent. Simboss does not knowingly collect
            personally identifiable information from users under 13. In the event that
            we learn that we have collected any personal information from a user under
            the age of 13, we will attempt to identify and delete that information from
            our database.
        </p>
        <p>
            5. International Usage
        </p>
        <p>
            The Service is owned by Simboss and may be accessed in Europe and abroad.
            For data protection purposes, Simboss is the controller and, unless
            otherwise noted, is also the processor of data. Information collected may be
            retained, and may be stored, processed, accessed, and used in jurisdictions
            whose privacy laws may be different and less protective than those of your
            home jurisdiction.
        </p>
        <p>
            Simboss has also contracted with third-party service providers to manage
            customer support for optimal service. Some data needs to be disclosed and
            transferred to this third party to ensure the fulfillment of the service.
            All third parties involved have been engaged under a binding confidentiality
            agreement and have limited access to the Simboss data for the purposes of
            providing support.
        </p>
        <p>
            6. How Does A User Change Or Update Information?
        </p>
        <p>
            If you have any questions or concerns about this privacy policy or would
            like the personally identifiable information that you have provided to be
            removed from our files, please contact Simboss via email at
            simbosskefu@qipeng.com.
        </p>
        <p>
            7. Security and Encryption
        </p>
        <p>
            We follow generally accepted industry standards to help protect your
            personal information. No method of transmission over the internet, mobile
            technology, or method of electronic storage, is completely secure.
            Therefore, while we endeavor to maintain physical, electronic, and
            procedural safeguards to protect the confidentiality of the information that
            we collect online, we cannot guarantee its absolute security. Our service
            has security measures in place designed to protect against the loss, misuse
            and alteration of the information under our control. We use standard Secure
            Socket Layer (SSL) encryption that encodes information for such
            transmissions. All service information is maintained on secure servers.
            Access to stored data is protected by multi-layered security controls
            including firewalls, role-based access controls and passwords. You are
            responsible to keep your password secure. If you have reason to believe that
            your interaction with us is no longer secure (for example, if you feel that
            the security of any account you might have with us has been compromised),
            please immediately notify us of the problem by contacting us at
            simbosskefu@qipeng.com.
        </p>
        <p>
            8. Changes to This Policy
        </p>
        <p>
            We reserve the right to change the terms of this privacy policy at any time.
            When we make changes, we will revise the last updated date at the top of the
            policy. If there are material changes to this statement or in how we will
            use your personal information, we will notify you by prominently posting a
            notice of such changes here or on our home page, or by sending you an email.
            We encourage you to review this policy whenever you visit one of our
            websites or applications.
        </p>
    </>
}
