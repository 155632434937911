import React from 'react';
import {List, Cell, Space, Typography, Tag} from 'react-vant';
import {dateFormat, volumUnitFormat} from "@/utils/util";
const colorMap = {
    'ACTIVE': 'success',
    'FINISHED': 'default',
    'PLAN': 'primary'
}
const PackageList: React.FC<any> = ({onLoad, data, finished = false}) => {
    return (
        <List onLoad={onLoad} finished={finished} style={{marginTop: '10px'}}>
            {data?.map((record) => (
                <Cell
                    title={<Space justify={'between'} align={"center"} block >
                        <Typography.Text strong>{volumUnitFormat(record?.baseTotalDataVolume, record.offerType, record.validity, record.validityUnit)}</Typography.Text>
                        <Tag type={colorMap[record.status]}>
                            {record.status}
                        </Tag>
                    </Space>}
                    key={record.id}
                    label={
                        <Space direction={"vertical"} style={{marginTop: 5}}>
                            <span>Validity Period: {dateFormat(record.effectiveDate, 'YYYY-MM-DD')} - {dateFormat(record.expirationDate, 'YYYY-MM-DD')}</span>
                        </Space>
                    }
                >
                </Cell>
            ))}
        </List>
    );
}

export default PackageList;
