import React from "react";

export default () => {
    return <>
        <h1 style={{textAlign: "center"}}>Shipping Policy</h1>
        <p>
            Regarding shipping and delivery:
        </p>
        <p>
            This platform only provides recharge services, which will be credited within 30 minutes after recharge, so
            there is no need for shipping or delivery services. Similarly, there is no delivery time issue.
        </p>
        <p>
            If the customer has any questions after completing the transaction and recharging, they can contact us
            simbosskefu@qipeng.com
        </p>
        <p>
            By placing an order, You confirm that You have read, understood and accepted these following policy.
        </p>
    </>
}
