import React from "react";

export default () => {
    return <>
        <p>
            You Can Also Contact Us Here:
        </p>
        <p>
            Company: Qipeng International Co., Limited
        </p>
        <p>
            Address: Unit 1002 10/F Perfect Commercial Building 20 Austin Avenue Tsim
            Sha Tsui KL
        </p>
        <p>
            If you are planning on visiting our offices for any reason, please call or
            email Customer Service ahead of time to schedule a visit.
        </p>
        <p>
            Phone: 400-668-5196
        </p>
        <p>
            Email: simbosskefu@qipeng.com
        </p>
    </>
}
