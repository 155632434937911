// src/components/LineChart.tsx
import React from 'react';
import ReactECharts from 'echarts-for-react';
import {dateFormat, volumeFormat} from "@/utils/util";
interface LineChartProps {
    data:Record<string, any>;
}

const UsageChart: React.FC<LineChartProps> = ({data}) => {
    console.log('🎸 DEBUG_6 UsageChart.tsx 👉', data)
    const options = {
        title: {
            show: false
        },
        tooltip: {
            trigger: 'axis',
            formatter: (p) => {
                return `${dateFormat( p[0].name, 'yyyy-MM-DD' )} </br>${
                    p.map(item => `${volumeFormat(item.value)}`)
                }`
            }
        },
        xAxis: {
            type: 'category',
            data:  data?.map(item => dateFormat(item.day, 'yyyy-MM-DD')),
            axisLabel: {
                formatter: (value: string) => `${dateFormat(value, 'DD')}`, // Custom formatter for x-axis labels
            },
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: data?.map(item => item.usage),
                type: 'line',
                label: {
                    show: true,
                    position: 'top',
                    formatter: '{c}M',
                },
                itemStyle: {
                    color: '#409eff',
                },
            },
        ],
        grid: {
            top: '3%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
    };

    return (
        <ReactECharts
            option={options}
            style={{ height: '200px', width: '100%' }}
        />
    );
};

export default UsageChart;
