import moment from 'moment'

const {abs} = Math;
const {isInteger, parseFloat, isNaN} = Number;
const multiple = 1024;

/**
 *
 * @param volume M
 * @param whether 是否需要带单位，默认为true
 * @returns {*}
 */
export const volumeFormat = function (volume: number | string, whether = true, unit = 'M') {
    const units = unit === 'M' ? ['M', 'G'] : ['K', 'M'];
    if (isNaN(+volume)) return volume;
    let result = volume;
    if (typeof result === 'string') result = parseFloat(result);
    // 小于 1G
    if (abs(result) < 1024) {
        // 包含小数
        if (!isInteger(result)) result = result.toFixed(2);
        return result + (whether ? units[0] : '');
    }
    result /= multiple;
    // 转成G包含小数
    if (!isInteger(result)) result = result.toFixed(2);
    return result + (whether ? units[1] : '');
};


export const volumUnitFormat = function (volume, offerType: string, validity: number, validityUnit: string) {
    if (offerType === 'MONTHLY') {
        return volumeFormat(volume) + ' / Month'
    }
    return volumeFormat(volume) + (validityUnit === "M" ? ` / ${validity || ''} Month(s)` : ` / ${validity || ''} Year(s)`)

}
export const dateFormat = (value: any, format = 'YYYY-MM-DD HH:mm:ss', fallback = '-',) => {
    if (
        typeof value !== 'string' &&
        typeof value !== 'number' &&
        !moment.isMoment(value) &&
        !moment.isDate(value)
    ) {
        return fallback;
    }
    if (moment.isMoment(value)) {
        return value.format(format || 'YYYY-MM-DD HH:mm:ss');
    }
    return moment(value).format(format || 'YYYY-MM-DD HH:mm:ss');
};
