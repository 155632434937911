import React, {useState} from 'react';
// import {List, Tabs, Tag, Space, InfiniteScroll} from 'antd-mobile';
import {Tabs} from 'react-vant';
import MainLayout from "@/layout/main/MainLayout";
import {useRequest} from "ahooks";
import {getHistoryRatePlan} from "@/api/query";
import {useNavigate, useSearchParams} from "react-router-dom";
import Usage from "@/pages/History/components/Usage";
import PackageList from "@/components/PackageList";


const Query: React.FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [listFinished, setListFinished] = useState(false)
    const [activeKey, setActiveKey] = useState('package');
    const {data: packageRecords, runAsync } = useRequest(() => getHistoryRatePlan({iccid: searchParams.get('iccid')}), {
        manual: true,
        onFinally() {
            setListFinished(true)
        }
    });
    return (
        <MainLayout
            title="History"
            navBarConfig={{
                onClickLeft: () => navigate(-1)
            }}
        >
            <Tabs active={activeKey} sticky lazyRender>
                <Tabs.TabPane title="package" key="package">
                    <PackageList onLoad={runAsync} data={packageRecords} finished={listFinished} />
                </Tabs.TabPane>
                <Tabs.TabPane title="usage" key="usage">
                    <Usage />
                </Tabs.TabPane>
            </Tabs>
        </MainLayout>
    );
}

export default Query;
