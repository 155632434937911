// src/components/GaugeChart.tsx
import React from 'react';
import ReactECharts from 'echarts-for-react';
import { Typography } from 'react-vant';
import {volumeFormat} from "@/utils/util";

interface GaugeChartProps {
    usageDataVolume: number;
    totalDataVolume: number;
}

const GaugeChart: React.FC<GaugeChartProps> = ({ usageDataVolume, totalDataVolume}) => {
    const option = {
        grid: {
            top: 0,
            left: 0,
            bottom: 0,
            right: 0
        },
        series: [
            {
                progress: {
                    show: true,
                    width: 30
                },
                type: 'gauge',
                radius: '100%', // Adjust the radius to fill the container
                axisLine: {
                    lineStyle: {
                        width: 30,
                        // color: [
                        //     [0.3, '#67e0e3'],
                        //     [0.7, '#37a2da'],
                        //     [1, '#fd666d']
                        // ]
                    }
                },
                title: {
                    // offsetCenter: ['0%', '70%']
                },
                center:  ['50%', '60%'],
                pointer: {
                    show: false,
                },
                axisTick: {
                    distance: -30,
                    length: 8,
                    lineStyle: {
                        color: '#fff',
                        width: 2
                    }
                },
                splitLine: {
                    distance: -30,
                    length: 30,
                    lineStyle: {
                        color: '#fff',
                        width: 4
                    }
                },
                axisLabel: {
                    show: false,
                    color: 'inherit',
                    distance: 40,
                    fontSize: 20
                },
                detail: {
                    valueAnimation: true,
                    formatter: (e) => {
                        return volumeFormat(totalDataVolume - usageDataVolume)
                    },
                    color: '#333',
                    offsetCenter: ['0%', '0%']

                },
                data: [
                    {
                        value: (usageDataVolume / totalDataVolume) * 100,
                        name: "Remaining Usage",
                    }
                ]
            }
        ]
    };

    return (
        <div style={{ width: '100%', height: '100%'}}>
            <ReactECharts option={option} style={{ height: '280px', width: '100%' }} />
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 40px', fontSize: '14px', color: '#888' }}>
                <Typography.Text strong style={{color: '#5470c6'}} center={true}>{volumeFormat(usageDataVolume)}  <br /> Used</Typography.Text>
                <Typography.Text strong={true} center={true}>{volumeFormat(totalDataVolume)}<br /> Total</Typography.Text>
            </div>
        </div>
    );
};

export default GaugeChart;
