import React, {useState} from 'react';
import {Empty, List, Tabs, Tabbar} from 'react-vant';
import MainLayout from "@/layout/main/MainLayout";
import {useRequest} from "ahooks";
import {renewableRatePlan} from "@/api/query";
import {useNavigate, useSearchParams} from "react-router-dom";
import SubscriptionItem from "@/pages/Renew/components/SubscriptionItem";

interface PackageRecord {
    id: number;
    data: string;
    totalDataVolume: number;
    validity: string;
    period: string;
    status: 'In Use' | 'Ended';
}

const Query: React.FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [renewListFinished, setRenewListFinished] = useState(false)
    const {
        data: packageRecords,
        runAsync: getRenewAble
    } = useRequest(() => renewableRatePlan({iccid: searchParams.get('iccid')}), {
        manual: true,
        onFinally() {
            setRenewListFinished(true)
        },
    });
    return (
        <MainLayout
            title="Renew"
            navBarConfig={{
                onClickLeft: () => navigate(-1)
            }}
        >
            <List onLoad={getRenewAble} finished={renewListFinished} style={{marginTop: '20px'}}>
                {packageRecords?.map((record) => (
                    <SubscriptionItem key={record.ratePlanId} data={record}/>
                ))}
            </List>
            {/*<Tabs active={'package'} onChange={handleTabChange} sticky>*/}
            {/*    <Tabs.TabPane title="package" key="package">*/}

            {/*        <Tabbar>*/}
            {/*            <Tabbar.Item onClick={() => navigate(`renew-records?iccid=${searchParams.get('iccid')}`)}>View*/}
            {/*                Renewal Records</Tabbar.Item>*/}
            {/*        </Tabbar>*/}
            {/*    </Tabs.TabPane>*/}
            {/*    <Tabs.TabPane title="Refill Pack" key="Refill">*/}
            {/*        <Empty description="No Data"/>*/}
            {/*    </Tabs.TabPane>*/}
            {/*</Tabs>*/}
        </MainLayout>
    );
}

export default Query;
