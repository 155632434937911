import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ConfigProvider } from "react-vant";
// import enUS from 'react-vant/es/locales/en-US'
import enUS from 'react-vant/es/locale/lang/en-US';




import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>
        <ConfigProvider locale={enUS}>
            <App />
        </ConfigProvider>
    // </React.StrictMode>
);

reportWebVitals();
