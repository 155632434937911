import React from 'react';
import {Button, Card, Space, Typography} from 'react-vant';
import {volumUnitFormat} from "@/utils/util";
import {useNavigate, useSearchParams} from "react-router-dom";

const SubscriptionItem: React.FC<any> = ({data}) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const buyNow = () => {

        navigate(`/payDetail?iccid=${searchParams.get('iccid')}&ratePlanId=${data.ratePlanId}&rechargeUnit=${data.rechargeUnit}&maxPeriod=${data.maxPeriod}&price=${data.price}&name=${volumUnitFormat(data.volume, data.offerType, data.validity, data.validityUnit)}&currency=${data.currency}`)
    }
    return (
        <Card style={{marginBottom: '10px'}}>
            <Card.Body style={{padding: '30px 20px'}}>
                <Space block={true} justify={"between"} align={'center'}>
                    <Space direction={"vertical"}>
                        {volumUnitFormat(data.volume, data.offerType, data.validity, data.validityUnit)}
                    </Space>
                    <Space block={true} justify={"between"} align={"center"}>
                        <Typography.Text type={"danger"} strong={true}>{data.currency} {data.price} </Typography.Text>
                        <Button type="primary" size="small" onClick={() => buyNow()}>
                            Buy Now
                        </Button>
                    </Space>
                </Space>
            </Card.Body>
        </Card>
    );
};

export default SubscriptionItem;
