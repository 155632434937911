import React from "react";

export default () => {
    return <>
        <h1 style={{textAlign: "center"}}>Return &amp; Refund policy</h1>
        <p>
            <strong>30-Day Exchange &amp; Return Policy</strong> <strong></strong>
        </p>
        <p>
            If there is something wrong with your order (such as a defective product, no
            signal, or connection failure, etc.), please contact us within 30 days of
            your purchase. We will be happy to assist you in reviewing the case. If we
            verify that the return and exchange criteria are met, then you can have a
            refund processed using the ICCID number in your order. The ICCID number is
            very important at the time of purchase because we need to return your money
            according to the correct ICCID number..
        </p>
        <p>
            Simboss reserves the right to refuse any return request, if the return is
            caused by malicious competitive business practices.
        </p>
        <p>
            <strong>Return&amp;Exchange Service Procedure:</strong> <strong></strong>
        </p>
        <p>
            1.Please login to your Simboss account and locate the order you are not
            satisfied with.
        </p>
        <p>
            2.Please contact Simboss support. Please select the service type, either an
            Exchange or Refund. Choose the specific SIM Card ICCID and describe the
            issues that have caused the need for a refund/exchange.
        </p>
        <p>
            3.Once we received your request, your exclusive Customer Service
            representative will email you back to rectify the issue within one working
            day (Beijing time). Please check your spam folder if you don't hear back
            from us prior to chasing us up as it might have gone into this folder.
            Please follow the instructions that are provided by our customer service
            representative.
        </p>
        <p>
            <strong>Return&amp;Service Office Address</strong> <strong></strong>
        </p>
        <p>
            Room 4503-1, Building 2, Euro America Financial City, Yuhang District,
            Hangzhou, Zhejiang
        </p>
        <p>
            Email：simbosskefu@qipeng.com
        </p>
    </>
}
