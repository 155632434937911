import React from 'react';
import {HashRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import Query from "./pages/Query";
import History from "@/pages/History";
import Home from "@/pages/Home";
import Plan from "@/pages/Plan";
import Renew from "@/pages/Renew";
import RenewRecord from "@/pages/RenewRecord";
import Done from "@/pages/Done";
import PayDetail from "@/pages/Renew/payDetail";
import Docs from "@/pages/Docs";

const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/query" element={<Query/>}/>
                <Route path="/history" element={<History/>}/>
                <Route path="/home" element={<Home/>}/>
                <Route path="/plan" element={<Plan/>}/>
                <Route path="/renew" element={<Renew/>}/>
                <Route path="/renew/renew-records" element={<RenewRecord/>}/>
                <Route path="/payDetail" element={<PayDetail/>}/>
                <Route path="/done" element={<Done/>}/>
                <Route path="/docs/:docId" element={<Docs/>}/>
                <Route path="*" element={<Navigate to="/query" />} />
            </Routes>
        </Router>
    );
}

export default App;
