import React from 'react';
import MainLayout from "@/layout/main/MainLayout";
import {Card} from 'react-vant';
import SimbossTOS from "./simboss.com/TermsOfService";
import SimbossPrivacyPolicy from "@/pages/Docs/simboss.com/PrivacyPolicy";
import SimbossShippingPolicy from "@/pages/Docs/simboss.com/ShippingPolicy";
import SimbossReturnRefund from "@/pages/Docs/simboss.com/return-refund";
import SimbossContactUs from "@/pages/Docs/simboss.com/ContactUs";

import IbillingTOS from "./ibilling.com.cn/TermsOfService";
import IbillingPrivacyPolicy from "@/pages/Docs/ibilling.com.cn/PrivacyPolicy";
import IbillingShippingPolicy from "@/pages/Docs/ibilling.com.cn/ShippingPolicy";
import IbillingReturnRefund from "@/pages/Docs/ibilling.com.cn/return-refund";
import IbillingContactUs from "@/pages/Docs/ibilling.com.cn/ContactUs";
import {useParams, useSearchParams} from 'react-router-dom';


const docMaps = {
    ['client-intl.simboss.com']: {
        'terms-of-service': {
            title: 'Terms Of Service',
            component: <SimbossTOS/>
        },
        'privacy-policy': {
            title: 'Privacy Policy',
            component: <SimbossPrivacyPolicy/>
        },
        'shipping-policy': {
            title: 'Shipping Policy',
            component: <SimbossShippingPolicy />
        },
        'return-and-refund-policy': {
            title: 'Return & Refund Policy',
            component: <SimbossReturnRefund/>
        },
        'contact-us': {
            title: 'Contact Us',
            component: <SimbossContactUs />
        }
    },
    ['client-intl.ibilling.com.cn']: {
        'terms-of-service': {
            title: 'Terms Of Service',
            component: <IbillingTOS/>
        },
        'privacy-policy': {
            title: 'Privacy Policy',
            component: <IbillingPrivacyPolicy/>
        },
        'shipping-policy': {
            title: 'Shipping Policy',
            component: <IbillingShippingPolicy />
        },
        'return-and-refund-policy': {
            title: 'Return & Refund Policy',
            component: <IbillingReturnRefund/>
        },
        'contact-us': {
            title: 'Contact Us',
            component: <IbillingContactUs />
        }
    }
}

const Docs: React.FC = () => {
    const {docId} = useParams();
    let company = docMaps?.[window.location.hostname]
    const [searchParams] = useSearchParams();

    if (!company) {
        company =  docMaps?.[searchParams.get('entityDomain')]
    }
    const doc = company?.[docId] || {};
    return (
        <MainLayout
            title={doc.title}
            navBarConfig={{
                leftArrow: false
            }}
        >
            <Card>
                <Card.Body>
                    {doc.component}
                </Card.Body>
            </Card>
        </MainLayout>
    );
}

export default Docs;
